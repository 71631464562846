import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Layout from "../components/layout"
import globalStyle from "../styles/global.module.css"
import styles from "./contact-us.module.css"
import formStyle from "./form.module.css"
import { Alert, Col, Form, Row } from "react-bootstrap"
import addToMailchimp from "gatsby-plugin-mailchimp"
import ContactEmailAddress from "../components/contactEmailAddress"

const ContactUsPage = () => {
  const [formState, setFormValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    message: "",
  })
  const [showAlert, setShowAlert] = useState(null)
  const [formResult, setFormResult] = useState({})
  const [showError, setShowErrorAlert] = useState({ show: false, onField: "" })
  const submitUrl =
    "https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&amp;id=6fb95bb68c"

  const resetFormAfterSubmit = () => {
    setFormValues({
      email: "",
      firstName: "",
      lastName: "",
      message: "",
    })
  }

  const _handleSubmit = async (e) => {
    e.preventDefault()

    const requiredFields = ["email", "firstName", "lastName", "message"]
    for (const field in formState) {
      if (requiredFields.indexOf(field) !== -1) {
        if (!formState[field]) {
          setShowErrorAlert({
            show: true,
            onField: field.charAt(0).toUpperCase() + field.slice(1),
          })

          return
        }
      }
    }

    const result = await addToMailchimp(
      formState.email,
      {
        FNAME: formState.firstName,
        LNAME: formState.lastName,
        MESSAGE: formState.message,
      },
      submitUrl
    )
    setFormResult(result)

    if (
      result.result === "error" &&
      result.msg.includes("is already subscribed to list")
    ) {
      setShowAlert({
        type: "danger",
        msg:
          "You've already submitted a contact request. Please email aimee@momsonmaternity.com instead.",
      })
    } else if (result.result === "error") {
      setShowAlert({
        type: "danger",
        msg:
          "Some error has occurred. Please send an email to " +
          <ContactEmailAddress />,
      })
    } else if (result.result === "success") {
      setShowAlert({
        type: "primary",
        msg: "We have recieved your email and will get back to you soon!",
      })
    }

    if (result.result === "success") {
      resetFormAfterSubmit()
    }
  }

  const handleFormChange = (e) =>
    setFormValues({
      ...formState,
      [e.target.name]: e.target.value,
    })

  return (
    <Layout className="site-content">
      <div>
        <div>
          <h1 className={globalStyle.entryTitle}>Contact us</h1>
        </div>

        <div className={styles.contactUsForm}>
          <Form onSubmit={_handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} controlId="firstName">
                <Form.Label
                  className={[formStyle.formLabel, formStyle.required]}
                >
                  First Name
                </Form.Label>
                <Form.Control
                  size="sm"
                  value={formState.firstName}
                  required
                  name="firstName"
                  type="text"
                  placeholder="First name"
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="lastName">
                <Form.Label
                  className={[formStyle.formLabel, formStyle.required]}
                >
                  Last Name
                </Form.Label>
                <Form.Control
                  size="sm"
                  value={formState.lastName}
                  required
                  name="lastName"
                  type="text"
                  placeholder="Last name"
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="formEmail">
              <Form.Label className={[formStyle.formLabel, formStyle.required]}>
                Email
              </Form.Label>
              <Form.Control
                size="sm"
                value={formState.email}
                required
                type="email"
                name="email"
                placeholder="Your email adress"
                onChange={handleFormChange}
              />
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label className={[formStyle.formLabel, formStyle.required]}>
                Comment or Message{" "}
              </Form.Label>
              <Form.Control
                size="sm"
                as="textarea"
                required
                value={formState.message}
                type="text"
                name="message"
                placeholder="Comment or Message"
                onChange={handleFormChange}
              />
            </Form.Group>

            <Button
              type="submit"
              className={[
                globalStyle.btnPrimary,
                globalStyle.redirectButtonPink,
              ]}
            >
              Submit
            </Button>
          </Form>

          <Row>
            <Col xs={12} sm={8}>
              {showAlert && (
                <Alert
                  variant={showAlert.type}
                  onClose={() => setShowAlert(null)}
                  dismissible
                >
                  {showAlert.msg}
                </Alert>
              )}
              {showError.show && (
                <Alert
                  variant="danger"
                  onClose={() => setShowErrorAlert({ show: false })}
                  dismissible
                >
                  {showError.onField} field should not be empty
                </Alert>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  )
}

export default ContactUsPage
